import Button from "@components/library/Button";
import { useBreakpoints } from "hooks/useBreakpoints";
import {
  ArrowUpRightIcon,
  BookOpenIcon,
  CloudIcon,
  StackIcon,
} from "@components/library/Icons";
import { CodeSnippetDiagram } from "./CodeSnippetDiagram";
import Carousel from "@components/library/Carousel";
import CarouselTweetCard from "@components/library/CarouselTweetCard";

interface TextWithButtonProps {
  button: React.ReactNode;
  header: string;
  description: string;
}

export const TextWithButton = ({
  header,
  description,
  button,
}: TextWithButtonProps) => (
  <div className="flex flex-row items-center justify-between gap-16 md:flex-col md:items-start md:gap-24 md:text-16-24">
    <div className="flex flex-col gap-8">
      <div className="text-13-14 font-medium text-gray-950 dark:text-white">
        {header}
      </div>
      <div className="text-12-16 text-gray-400 dark:text-gray-300">
        {description}
      </div>
    </div>
    {button}
  </div>
);

interface IconWithTextProps {
  icon: React.ReactNode;
  header: string;
  description: string;
}

export const IconWithText = ({
  icon,
  header,
  description,
}: IconWithTextProps) => (
  <div className="flex flex-col gap-12 md:gap-32">
    <div className="flex flex-row items-start  gap-16 text-14-20 font-medium md:gap-24 md:text-16-24">
      <div className="rounded-[12px] p-10 shadow-d dark:bg-gray-800 md:p-12">
        {icon}
      </div>

      <div className="pt-10 text-gray-950 dark:text-white">
        {header}{" "}
        <span className="text-gray-400 dark:text-gray-300">{description}</span>
      </div>
    </div>
  </div>
);

export const DevelopersCarouselSection = () => {
  return (
    <div>
      <div className="container-lg mb-20">
        <div className="col-span-full mt-48 text-left dark:text-white md:mt-64">
          <p className="flex flex-col self-stretch font-display text-[24px] leading-[100%]  ">
            Loved by developers and product teams
          </p>
        </div>
      </div>
      <Carousel
        shadowColor="from-white dark:from-gray-950"
        initialScroll={0}
        slides={[
          <CarouselTweetCard
            key={"Aman Sanger"}
            profileImage="/assets/customers/aman-cursor.jpeg"
            user="Aman Sanger"
            userHandle="Cursor"
            link="https://cursor.so/"
            content={
              <div>
                <span>
                  Humanloop has been great for monitoring how users are invoking
                  Cursor. This is useful for tailoring the product in a
                  direction that best supports common use-cases.
                </span>
              </div>
            }
          />,
          <CarouselTweetCard
            key={"@cj_clifton"}
            profileImage="/assets/customers/cj-clifton.jpeg"
            user="Casey Clifton"
            link="https://twitter.com/cj_clifton/status/1640699609357111297"
            userHandle="@cj_clifton"
            content={
              <div>
                <span>
                  Using GPT in your product or business? This is a non-sponsored
                  recommendation to check out{" "}
                  <span className="text-blue-500">@humanloop</span> - it lets
                  you easily track and identify which prompts work best for your
                  users 🚀
                </span>
              </div>
            }
          />,
          <CarouselTweetCard
            key={"Amélie Chatelain"}
            profileImage="/assets/customers/amelie-lighton.jpeg"
            user="Amélie Chatelain, LightOn"
            userHandle="LightOn"
            link="https://lighton.ai/"
            content={
              <div>
                <span>
                  Humanloop has been invaluable for evaluating our models — the
                  direct, quantitative metrics aligned with human judgments have
                  guided us to maximize model performance!
                </span>
              </div>
            }
          />,

          <CarouselTweetCard
            key={"Cherian Koshy"}
            profileImage="/assets/customers/cherian-nonprofitos.jpeg"
            user="Cherian Koshy"
            userHandle="NonProfitOS"
            link="https://nonprofitoperatingsystem.com/"
            content={
              <div>
                <span>
                  I was searching for a solution to differentiate ourselves from
                  vanilla applications that didn't focus on the specific needs
                  of our sector. Humanloop is a must-have resource.
                </span>
              </div>
            }
          />,

          <CarouselTweetCard
            key={"Michael Slade"}
            profileImage="/assets/customers/michael-retainit.jpeg"
            user="Michael Slade"
            userHandle="Retainit"
            link="https://retainit.app/"
            content={
              <div>
                <span>
                  This allows us to achieve a level of sophistication with GPT
                  that would otherwise be impossible.
                </span>
              </div>
            }
          />,

          <CarouselTweetCard
            key={"Chris Dossman"}
            profileImage="/assets/customers/chris-faqx.jpeg"
            user="Chris Dossman"
            userHandle="FAQx"
            link="https://faqx.com/"
            content={
              <div>
                <span>
                  Been using Humanloop for a while and it really helps us build
                  our LLM applications. Their one-click finetuning pipeline is
                  great.
                </span>
              </div>
            }
          />,

          <CarouselTweetCard
            key={"John Shahawy"}
            profileImage="/assets/customers/john-moonbeam.jpeg"
            user="John Shahawy"
            userHandle="Moonbeam"
            link="http://gomoonbeam.com/"
            content={
              <div>
                <span>
                  Humanloop helped us ship and scale to thousands of DAUs.
                  Version management, A/B testing prompts and fine tuning have
                  been game changers.
                </span>
              </div>
            }
          />,
          <CarouselTweetCard
            key={"@stewfortier"}
            profileImage="/assets/customers/stew-fortier.jpeg"
            user="Stew Fortier"
            link="https://twitter.com/stewfortier/status/1630718464712384512"
            userHandle="@stewfortier"
            content={
              <div>
                <span>
                  If you're building stuff with LLMs, I highly recommend
                  following <span className="text-blue-500">@RazRazcle</span> &{" "}
                  <span className="text-blue-500">@humanloop</span>. One of the
                  best teams in the space
                </span>
              </div>
            }
          />,
          <CarouselTweetCard
            key={"@ivee"}
            profileImage="/assets/customers/ivee.jpeg"
            user="Ivee"
            userHandle="@ivee"
            content={
              <div>
                <span>
                  I've been using{" "}
                  <span className="text-blue-500">@humanloop</span> for work,
                  and it is such a powerful AI tool.
                </span>
                <br />
                <span>
                  You can create prompts that will automate your otherwise
                  time-consuming tasks!
                </span>
              </div>
            }
          />,
        ]}
      />
    </div>
  );
};
