import React, { useState } from "react";

export interface CarouselTweetCardProps {
  profileImage: string;
  user: string;
  userHandle: string;
  content: React.ReactNode;
  link?: string;
}

const CarouselTweetCard = ({
  profileImage,
  user,
  userHandle,
  content,
  link,
}: CarouselTweetCardProps) => {
  return (
    <div className="md:w-[350px] flex w-[300px] flex-col justify-start  gap-24 rounded-[12px] border-[0.5px] border-gray-800 bg-gray-900 p-24 shadow-card">
      <div className="flex flex-row items-center gap-16">
        <img
          className="h-32 w-32 rounded-full"
          src={profileImage}
          alt="profile"
        />
        <div className="flex flex-col justify-start gap-4">
          <div className="text-13-14 text-white ">{user}</div>
          <div className="text-12-14 text-gray-400">{userHandle}</div>
        </div>
      </div>
      <div className="mt-12 text-14-20 text-gray-150">{content}</div>
    </div>
  );
};

export default CarouselTweetCard;
