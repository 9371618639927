import Button from "@components/library/Button";
import { Lissajous3Icon } from "@components/library/Icons";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import {
  analytics,
  EventPage,
  EventPurpose,
  EventSection,
} from "services/analytics.service";
import { HUMANLOOP_APP_SIGNUP } from "utils/constants";

interface ProConListProps {
  icon: React.ReactNode;
  header: string;
  descriptions: React.ReactNode[];
}

export const ProConList = ({ icon, header, descriptions }: ProConListProps) => (
  <div className="flex flex-col gap-20">
    <div className="text-14-14 font-bold text-gray-950 md:text-16-16">
      {header}
    </div>
    <div className="flex flex-col gap-8 text-12-20 md:text-12-20 lg:text-14-20">
      {descriptions.map((description, index) => (
        <div
          key={index}
          className="flex flex-row items-center justify-start gap-8"
        >
          {icon}
          <div className="whitespace-nowrap">{description}</div>
        </div>
      ))}
    </div>
  </div>
);

export const TheProblemSection = () => {
  return (
    <div className="container-lg mx-12 grid max-w-[1160px] grid-cols-12 gap-y-32 overflow-hidden text-center  md:mx-auto">
      <div className="col-span-full text-start">
        <h2>
          <div className="mt-16 justify-start text-start font-display text-[24px] leading-[100%] text-gray-600 md:mt-40 md:text-[40px] ">
            Building with AI is a team effort.
          </div>
          <div className="justify-start text-start font-display text-[24px] leading-[100%] md:text-[40px] ">
            You're wasting time with bad workflows.
          </div>
        </h2>
      </div>
      <div className="col-span-full text-left">
        <div className="flex w-full flex-col justify-between gap-32 md:h-[290px] md:flex-row md:gap-8 md:text-left lg:h-[330px] lg:gap-32">
          <div className="w-full rounded-br-[28px] p-1 md:bg-gradient-to-br md:from-white md:via-white md:to-gray-200">
            <div className="flex h-full flex-col justify-between gap-32 bg-white md:gap-0 md:rounded-br-[28px] md:pb-20 md:pt-40 lg:py-40">
              <div className="flex select-none flex-row gap-24 overflow-hidden pt-10">
                <img
                  src="/assets/customers/google-sheets.svg"
                  alt="google-sheets"
                  className="h-16 opacity-70 grayscale"
                />
                <img
                  src="/assets/customers/mixpanel.svg"
                  alt="mixpanel"
                  className="h-16 opacity-50 grayscale "
                />
                <img
                  src="/assets/customers/segment.svg"
                  alt="segment"
                  className="h-16 opacity-40 grayscale"
                />
                <img
                  src="/assets/customers/optimizely.svg"
                  alt="optimizely"
                  className="hidden h-16 opacity-30 grayscale lg:block"
                />
              </div>
              <ProConList
                icon={
                  <XIcon className="flex h-[14px] w-[14px] flex-shrink-0 text-gray-700 md:h-[16px] md:w-[16px]" />
                }
                header="Sound familiar?"
                descriptions={[
                  <div key={"copy-pasting"} className="text-gray-700">
                    <span className="font-bold">Copy-pasting</span> prompts
                    between OpenAI and code
                  </div>,
                  <div key={"prompts"} className="text-gray-700">
                    Prompts managed in{" "}
                    <span className="font-bold">spreadsheets</span>
                  </div>,
                  <div key={"manual-evaluation"} className="text-gray-700">
                    High effort{" "}
                    <span className="font-bold">manual evaluation</span> and
                    workflows
                  </div>,
                  <div
                    key={"performance-regressions"}
                    className="text-gray-700"
                  >
                    Changes causing{" "}
                    <span className="font-bold">performance regressions</span>
                  </div>,
                ]}
              />
            </div>
          </div>
          <div className="w-full md:rounded-bl-[28px] md:bg-gradient-to-bl md:from-white md:via-white md:to-blue-400 md:p-1">
            <div className="flex h-full flex-col justify-between gap-20 bg-white md:rounded-bl-[28px] md:pb-20 md:pl-20 md:pt-40 lg:py-40 lg:pl-40">
              <div className="hidden md:flex md:h-40 md:w-40 md:rounded-[12px] md:p-12 md:shadow-d">
                <Lissajous3Icon className="flex h-[16px] w-[16px] flex-shrink-0" />
              </div>
              <ProConList
                icon={
                  <CheckIcon className="flex h-[14px] w-[14px] flex-shrink-0 text-blue-900 md:h-16 md:w-16" />
                }
                header="Humanloop solves this"
                descriptions={[
                  <div
                    key={"collaborative_playground"}
                    className="text-blue-900"
                  >
                    <span className="font-bold">Collaborative playground</span>{" "}
                    with history
                  </div>,
                  <div key={"prompt_management"} className="text-blue-900">
                    <span className="font-bold">Prompt management</span> and
                    deployment controls
                  </div>,
                  <div key={"backtest_changes"} className="text-blue-900">
                    Backtest changes and{" "}
                    <span className="font-bold">confidently update models</span>
                  </div>,
                  <div key={"capture_feedback"} className="text-blue-900">
                    Capture feedback and run{" "}
                    <span className="font-bold">quantitative experiments</span>
                  </div>,
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
