import { useWindowSize } from "usehooks-ts";

// Media breakpoints with same widths as Tailwind CSS
export const useBreakpoints = () => {
  const { width, height } = useWindowSize();

  const isLessThanSmallWidth = width < 640;
  const isSmallWidth = width >= 640;
  const isMediumWidth = width >= 768;
  const isLargeWidth = width >= 1024;

  return {
    width,
    isLessThanSmallWidth,
    isSmallWidth,
    isMediumWidth,
    isLargeWidth,
  };
};
