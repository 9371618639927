import { LissajousAnimated } from "@components/Lissajous";
import Button from "@components/library/Button";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { DEMO_FORM_LINK, HUMANLOOP_APP_SIGNUP } from "utils/constants";

import {
  AnthropicIcon,
  GoogleGIcon,
  OpenAIIcon,
} from "@components/library/Icons";
import { ArrowRightIcon } from "@heroicons/react/outline";
import {
  EventPage,
  EventPurpose,
  EventSection,
  analytics,
} from "services/analytics.service";

export const SubheadingList = () => (
  <div className="mt-32 flex flex-col gap-12 md:mt-40">
    <div className="flex flex-row items-center gap-8 md:gap-12">
      <CheckCircleIcon className="h-20 w-20 shrink-0 fill-gray-800 md:h-24 md:w-24" />
      <div className="text-14-14 text-gray-800  md:text-16-24">
        Find, manage and measure prompts for LLMs
      </div>
    </div>
    <div className="flex flex-row items-center gap-8 md:gap-12">
      <CheckCircleIcon className="h-20 w-20 shrink-0 fill-gray-800 md:h-24 md:w-24" />
      <div className="text-14-14 text-gray-800 md:text-16-16">
        Seamless collaboration across teams
      </div>
    </div>
    <div className="items flex flex-row items-center gap-8 md:gap-12">
      <CheckCircleIcon className="h-20 w-20 shrink-0 fill-gray-800 md:h-24 md:w-24" />
      <div className="text-14-14 text-gray-800 md:text-16-16">
        Enterprise-grade evaluations and monitoring
      </div>
    </div>
  </div>
);

export const HeroSection = () => {
  return (
    <div className="container-lg grid-12  mx-12 max-w-[1160px] text-center md:mx-auto">
      <div className="col-span-full text-left md:col-span-7 ">
        <h1 className="mt-24 flex flex-col self-stretch font-display text-[34px]  max-w-lg leading-[100%] md:mt-[28px] md:w-[95%] md:text-[56px] ">
          Develop AI features with confidence
        </h1>
        <SubheadingList />
        <div className="mt-32 flex max-w-md flex-row items-start gap-12 md:mt-40">
          <div className="flex w-2/3 flex-col items-center gap-12 md:w-1/2 md:max-w-[238px]">
            <Button
              className="w-full h-48 font-bold shadow-button-1 hover:shadow-button-2"
              type="submit"
              styling="solid"
              size={48}
              IconRight={ArrowRightIcon}
              href={DEMO_FORM_LINK}
              onClick={() => {
                analytics.track("Button Clicked", {
                  page: EventPage.Home,
                  section: EventSection.Hero,
                  purpose: EventPurpose.Sales,
                  url: DEMO_FORM_LINK,
                });
              }}
            >
              Book a demo
            </Button>
          </div>
          <Button
            size={48}
            className="w-1/2 h-48 whitespace-nowrap font-normal text-gray-900 hover:border-gray-200 hover:bg-gray-100 hover:shadow-button-2 md:w-1/3 md:max-w-[135px]"
            styling="outline"
            shade="gray"
            href={HUMANLOOP_APP_SIGNUP}
            onClick={() => {
              analytics.track("Button Clicked", {
                page: EventPage.Home,
                section: EventSection.Hero,
                purpose: EventPurpose.Signup,
                url: HUMANLOOP_APP_SIGNUP,
              });
            }}
          >
            Try for free
          </Button>
        </div>
      </div>
      <div className="col-end-12 hidden md:col-start-8 md:flex lg:col-start-8">
        <div className="flex flex-row justify-center gap-[20px] align-middle">
          <div className="flex flex-col items-end gap-[20px]">
            <div className="relative col-start-3  h-[232px] w-[232px] items-center justify-center rounded-ms border border-gray-950  bg-white bg-[url('/assets/grid.svg')] bg-center shadow-e">
              <div className="absolute right-16 top-12 items-center justify-center rounded bg-gray-100 p-4 text-10-10 font-medium text-gray-950">
                <div className="flex select-none flex-row gap-4">
                  <OpenAIIcon className="h-10 w-10" /> GPT-4
                </div>
              </div>
              <div className="h-full w-full">
                <LissajousAnimated
                  lissajousNumber={3}
                  size={116}
                  animateOnHover
                  start={-89.5}
                  end={90.5} // Not exactly 90 to avoid the SVG collapsing to a very thin line
                />
              </div>
            </div>
            <div className="relative col-start-3 flex h-[168px] w-[168px] items-center justify-center rounded-ms bg-white shadow-e">
              <div className="absolute right-12 top-12 items-center justify-center rounded bg-gray-100 p-4 text-10-10 font-medium text-gray-950">
                <div className="flex select-none flex-row gap-4">
                  <GoogleGIcon className="h-10 w-10" /> Gemini
                </div>
              </div>
              <LissajousAnimated
                lissajousNumber={2}
                size={88}
                animateOnHover
                start={0}
                end={0}
              />
            </div>
          </div>
          <div className="mt-64 flex flex-col gap-[20px]">
            <div className="w-full h-40 " />
            <div className="relative flex h-[168px] w-[168px] items-center justify-center rounded-ms bg-white shadow-e ">
              <div className="absolute right-12 top-12 items-center justify-center rounded bg-gray-100 p-4 text-10-10 font-medium text-gray-950">
                <div className="flex select-none flex-row gap-4">
                  <AnthropicIcon className="h-10 w-10 stroke-[#18181B]" />
                  Claude
                </div>
              </div>
              <LissajousAnimated
                lissajousNumber={4}
                size={88}
                animateOnHover
                start={0}
                end={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
