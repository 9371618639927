import Carousel from "@components/library/Carousel";
import CarouselCompanyCard from "@components/library/CarouselCompanyCard";

interface TextWithButtonProps {
  button: React.ReactNode;
  header: string;
  description: string;
}

export const TextWithButton = ({
  header,
  description,
  button,
}: TextWithButtonProps) => (
  <div className="flex flex-row items-center justify-between gap-16 md:flex-col md:items-start md:gap-24 md:text-16-24">
    <div className="flex flex-col gap-8">
      <div className="text-13-14 font-medium text-gray-950 dark:text-white">
        {header}
      </div>
      <div className="text-12-16 text-gray-400 dark:text-gray-300">
        {description}
      </div>
    </div>
    {button}
  </div>
);

interface IconWithTextProps {
  icon: React.ReactNode;
  header: string;
  description: string;
}

export const IconWithText = ({
  icon,
  header,
  description,
}: IconWithTextProps) => (
  <div className="flex flex-col gap-12 md:gap-32">
    <div className="flex flex-row items-start gap-16 text-14-20 font-medium md:gap-24 md:text-16-24">
      <div className="rounded-[12px] p-10 shadow-d dark:bg-gray-800 md:p-12">
        {icon}
      </div>

      <div className="pt-10 text-gray-950 dark:text-white">
        {header}{" "}
        <span className="text-gray-400 dark:text-gray-300">{description}</span>
      </div>
    </div>
  </div>
);

export const EnterpriseCarouselSection = () => {
  return (
    <div>
      <Carousel
        cardGap={32}
        slides={[
          <CarouselCompanyCard
            key={"athena"}
            companyLogo="/assets/customers/athena.png"
            logoClasses="!h-1/6"
            companyAlt="Athena"
            caseStudyLink="/customer/athena"
            header="Athena empowers their EAs with AI through Humanloop"
            content={
              <div className="flex flex-col gap-12 text-gray-950">
                <span>
                  With Humanloop, Athena supercharges their Executive Assistants
                  with shared prompts and gives them the tools to automate
                  complex workflows.
                </span>
              </div>
            }
            metrics={[
              {
                value: "600 +",
                label: "EAs on Humanloop",
              },

              {
                value: "200 +",
                label: "AI projects",
              },
              {
                value: "50k +",
                label: "Logs collected",
              },
            ]}
          />,

          <CarouselCompanyCard
            key={"duolingo"}
            companyLogo="/assets/customers/duolingo-white.svg"
            companyAlt="Duolingo"
            header="Duolingo uses Humanloop to optimize their AI teaching experience for 50M users"
            content={
              <div className="flex flex-col gap-12 text-gray-950">
                <span>
                  Duolingo Max is revolutionizing language learning with AI.
                  They use Humanloop to manage prompts, evaluate their models
                  and collaborate across teams.
                </span>
              </div>
            }
            metrics={[
              {
                value: "50M",
                label: "Monthly learners",
              },
              {
                value: "1000 +",
                label: "Employees",
              },
              {
                value: "40 +",
                label: "AI projects",
              },
            ]}
          />,
          <CarouselCompanyCard
            key={"gusto"}
            companyLogo="/assets/customers/gusto-white.svg"
            logoClasses="mt-12"
            companyAlt="Gusto"
            header="Gusto are transforming their internal operations with AI"
            content={
              <div className="text-gray-950 ">
                <p>
                  Gusto are transforming their payroll experience and internal
                  operations with AI. Within a month of adopting Humanloop,
                  Gusto deployed an AI feature to production.
                </p>
              </div>
            }
            metrics={[
              {
                value: "200k +",
                label: "Customer",
              },
              {
                value: "$500m +",
                label: "Revenue",
              },
              {
                value: "1 month",
                label: "to first production feature",
              },
            ]}
          />,
          <CarouselCompanyCard
            key={"oviva"}
            companyLogo="/assets/customers/oviva-white.svg"
            companyAlt="Oviva Group"
            header="Oviva Group enables AI for healthcare with Humanloop"
            content={
              <div className="text-gray-950">
                <span>
                  Oviva are using AI to provide scalable dietary advice to
                  thousands. They trust Humanloop to manage the process of
                  making sure the AI is controllable.
                </span>
              </div>
            }
            metrics={[
              {
                value: "200k +",
                label: "Customers",
              },
              {
                value: "250+",
                label: "Coaches",
              },
              {
                value: "300K+",
                label: "People treated",
              },
            ]}
          />,
        ]}
      />
    </div>
  );
};
