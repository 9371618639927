export const WebsiteGradient = (
  props: React.ComponentProps<"svg">,
): JSX.Element => (
  <svg
    width="1440"
    height="290"
    viewBox="0 0 1440 290"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_1010_72837)">
      <path
        d="M-59 80C576.882 200.375 918.228 201.312 1499 80V580H-59V80Z"
        fill="url(#paint0_linear_1010_72837)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1010_72837"
        x="-139"
        y="0"
        width="1718"
        height="660"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="40"
          result="effect1_foregroundBlur_1010_72837"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1010_72837"
        x1="720"
        y1="80"
        x2="720"
        y2="330"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#67A3FB" stopOpacity="0" />
        <stop offset="1" stopColor="#000042" />
      </linearGradient>
    </defs>
  </svg>
);
