import React from "react";
import { classNames } from "./utils/classNames";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import IconButton from "./IconButton";

interface CarouselProps {
  slides: JSX.Element[];
  shadowColor?: string;
  cardGap?: 16 | 24 | 32;
  initialScroll?: number;
}

const Carousel = ({
  slides,
  shadowColor,
  cardGap = 24,
  initialScroll = 0, // Set initialScroll to 0
}: CarouselProps) => {
  const carouselRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = initialScroll;
    }
  }, [initialScroll]); // Update dependency array

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <div className="relative w-full">
      <div
        className={classNames(
          "absolute z-10 bottom-0 left-0 top-0 w-[5%] overflow-hidden bg-gradient-to-r",
          shadowColor,
        )}
      />
      <div
        className={classNames(
          "absolute z-10 bottom-0 right-0 top-0 w-[5%] overflow-hidden bg-gradient-to-l",
          shadowColor,
        )}
      />
      <div
        className="scrollbar-hide flex w-full overflow-x-scroll overscroll-x-auto scroll-smooth py-20 "
        ref={carouselRef}
      >
        <div
          className={classNames(
            "absolute right-0  z-[1000] h-auto  w-[5%] overflow-hidden bg-gradient-to-l",
            shadowColor,
          )}
        ></div>

        <div
          className={classNames(
            // excessive bottom padding as some shadows are massive, see equiv. neg margin on the arrows below
            "container-lg mx-auto pb-64 flex flex-row justify-start",
            cardGap === 32
              ? "gap-[32px]"
              : cardGap === 24
              ? "gap-[24px]"
              : "gap-[16px]",
          )}
        >
          {slides.map((slide, index) => (
            <div key={index} className="last:pr-[33%]">
              {slide}
            </div>
          ))}
        </div>
      </div>
      <div className="flex -mt-52 opacity-50 justify-end container-lg gap-10 md:hidde">
        <IconButton
          onClick={scrollLeft}
          shade="gray"
          Icon={ArrowLeftIcon}
          size={32}
        />
        <IconButton
          onClick={scrollRight}
          shade="gray"
          Icon={ArrowRightIcon}
          size={32}
        />
      </div>
    </div>
  );
};

export default Carousel;
