import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { classNames } from "@components/library/utils/classNames";
import python from "react-syntax-highlighter/dist/cjs/languages/hljs/python";
import typescript from "react-syntax-highlighter/dist/cjs/languages/hljs/typescript";
import styles from "react-syntax-highlighter/dist/cjs/styles/hljs/a11y-dark";

import React from "react";

SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("typescript", typescript);

const PYTHON_CODE = `from humanloop import Humanloop

humanloop = Humanloop(
    api_key="HUMANLOOP_API_KEY",
    openai_api_key="OPENAI_API_KEY",
)

response = humanloop.complete(
    project="sdk-example",
    model_config={
      "model": "gpt-4",
      "prompt_template": "Write a joke about {{topic}}",
    },
    inputs={"topic": "stochastic parrots"}
)`;

const TYPESCRIPT_CODE = `import { Humanloop } from "humanloop"

const humanloop = new Humanloop({
  apiKey: 'HUMANLOOP_API_KEY',
  openaiApiKey: "OPENAI_API_KEY",
})

const chatResponse = await humanloop.chat({
  "project": "sdk-example",
  "messages": [
    {
      "role": "user",
      "content": "Write a short novel about human-robot love",
    }
  ],
  "model_config": {
    "model": "claude-100k",
    "temperature": 1,
  },
})

console.log(chatResponse)
`;

export const CodeSnippet = () => {
  const [activeTab, setActiveTab] = React.useState<"python" | "typescript">(
    "python",
  );

  return (
    <div
      className={
        "grid min-h-[200px] w-full min-w-[280px] grid-cols-12 grid-rows-6"
      }
    >
      <div className="z-10 col-span-10 col-start-3 row-span-5 row-start-1 flex h-[190px] self-center rounded  border border-gray-800 bg-gray-900 md:h-[250px]">
        <div className="inline-flex h-40  items-center gap-8 whitespace-nowrap  px-[24px]">
          {activeTab === "python" ? (
            // TODO: animate on toggle
            <div className="animate-typing bg-gradient-to-r from-gray-200 to-[#7070e4] bg-clip-text font-mono text-14-14 font-bold text-transparent ">
              pip install humanloop
            </div>
          ) : (
            <div className="animate-typing bg-gradient-to-r from-gray-200 to-[#7070e4] bg-clip-text font-mono text-14-14 font-bold text-transparent ">
              npm install humanloop
            </div>
          )}
          <div className="h-[14px] w-[8px] bg-[#7070e4] text-transparent" />
        </div>
      </div>

      <div className="z-20 col-span-11 col-start-1 row-span-5 row-start-2 flex flex-col text-left  ">
        <div className="flex flex-row">
          <div
            onClick={() => setActiveTab("python")}
            className={classNames(
              "min-w-[100px] cursor-pointer  border  border-gray-800 bg-gray-900 px-[14px] py-[7px] text-12-12 font-medium",
              activeTab === "python"
                ? "z-30 cursor-default rounded-t border-b-0 text-gray-300"
                : "z-20 rounded-tl border-b border-r-0 text-gray-400 hover:bg-gray-800",
            )}
          >
            Python SDK
          </div>
          <div
            onClick={() => setActiveTab("typescript")}
            className={classNames(
              "-ml-4 min-w-[120px] cursor-pointer rounded-tr border border-gray-800 bg-gray-900 px-[14px] py-[7px] text-12-12 font-medium text-gray-400",
              activeTab === "typescript"
                ? "z-30 cursor-default rounded-t border-b-0 border-l pl-[13px] pr-[15px] text-gray-300"
                : "z-20 rounded-tr border-b border-l-0 text-gray-400 hover:bg-gray-800",
            )}
          >
            TypeScript SDK
          </div>
          <div className="z-[15] -ml-4 w-full rounded-tr border border-l-0 border-gray-800 bg-gray-900" />
        </div>
        <div
          className={
            "flex h-[160px] flex-row gap-16 rounded-b border border-t-0 border-gray-800 bg-gray-900 p-[10px] pt-8 md:h-[250px]"
          }
        >
          <SyntaxHighlighter
            language={activeTab}
            style={styles}
            showLineNumbers={true}
            wrapLines={true}
            lineNumberStyle={{
              color: "#71717A",
            }}
            customStyle={{
              fontSize: "12px",
              lineHeight: "14px",
              backgroundColor: "transparent",
              paddingLeft: 4,
              paddingRight: 4,
              width: "100%",
            }}
            className="scrollbar-transparent"
          >
            {activeTab === "python" ? PYTHON_CODE : TYPESCRIPT_CODE}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
};
