import React, { useState } from "react";
import { classNames } from "./utils/classNames";
import { ConditionalWrapper } from "./utils/wrap";
import Link from "next/link";
import Image from "next/image";

export interface MetricsProps {
  value?: string;
  label: string;
  shortLabel?: string;
  number?: number | string;
}

export interface CarouselCompanyCardProps {
  companyLogo: string;
  companyAlt: string;
  logoClasses?: string;
  header: string;
  content: React.ReactNode;
  metrics: MetricsProps[];
  caseStudyLink?: string;
}

const CarouselCompanyCard = ({
  companyLogo,
  companyAlt,
  logoClasses,
  header,
  content,
  metrics,
  caseStudyLink,
}: CarouselCompanyCardProps) => {
  return (
    <ConditionalWrapper
      condition={caseStudyLink !== undefined}
      wrapper={(children) => (
        <Link href={caseStudyLink as string}>{children}</Link>
      )}
    >
      <div
        className={classNames(
          "flex border h-full w-full min-w-[300px] flex-col justify-start overflow-clip rounded-[12px] shadow-xl md:h-full md:w-full md:min-w-[444px]",
        )}
      >
        <div className="flex h-[200px] flex-row items-center justify-center bg-black">
          <Image
            className={classNames("h-1/6 object-contain", logoClasses)}
            src={companyLogo}
            alt={companyAlt}
            height="500"
            width="500"
          />
        </div>
        <div className="flex flex-1 flex-col items-center justify-between px-16 py-20 md:px-32 md:py-40">
          <div className="flex flex-col items-start justify-start gap-16">
            <div className="font-display text-[18px] font-medium leading-[100%] text-gray-950 md:text-[26px] ">
              {header}
            </div>
            <div className="text-12-16 text-gray-150 md:text-14-20">
              {content}
            </div>
          </div>

          <div className="flex w-full flex-row items-start justify-start gap-32 mt-20">
            {metrics.map((metric: MetricsProps, index: number) => (
              <div
                key={index}
                className="flex flex-col items-start justify-start gap-6"
              >
                <div className="text-13-14 font-bold text-gray-900 md:text-14-16">
                  {metric.number ? metric.number : metric.value}
                </div>
                <div className="text-13-14 font-medium text-gray-400 md:text-14-16">
                  {metric.shortLabel ? metric.shortLabel : metric.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default CarouselCompanyCard;
