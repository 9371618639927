import React from "react";

export const DarkGridBackground = ({
  children,
}: React.PropsWithChildren<{}>): JSX.Element => {
  return (
    <div className="relative">
      <div className="absolute top-0 bottom-0 left-0 right-0 -z-10 overflow-hidden bg-gray-950">
        <div
          className="absolute right-0 bottom-0 z-auto h-full w-1/2 animate-fade-in-up "
          style={{
            backgroundImage: `url("/assets/square-dark.svg")`,
          }}
        >
          <div className="absolute right-0 bottom-0 mb-2 h-full w-full bg-gradient-to-r from-gray-950 via-transparent"></div>
          <div className="absolute right-0 bottom-0 mb-2 h-full w-full bg-gradient-to-t from-gray-950 via-transparent"></div>
        </div>
      </div>
      {children}
    </div>
  );
};
