import Button from "@components/library/Button";
import {
  ArrowUpRightIcon,
  LightBuildIcon,
  ShieldIcon,
} from "@components/library/Icons";
import { SupportIcon } from "@heroicons/react/outline";
import {
  EventPage,
  EventPurpose,
  EventSection,
  analytics,
} from "services/analytics.service";
import { DEMO_FORM_LINK, VANTA_TRUST_REPORT_LINK } from "utils/constants";
import { Globe } from "./images/Globe";

interface MetricProps {
  label: string;
  value: string;
}

export const Metric = ({ label, value }: MetricProps) => (
  <div className="flex flex-col gap-6 md:gap-16">
    <div className="text-12-12 font-bold text-gray-400 md:text-gray-700">
      {label}
    </div>
    <div className="font-display text-[24px] font-normal leading-[100%] text-gray-800 md:text-[40px]">
      {value}
    </div>
  </div>
);

interface IconWithTextProps {
  icon: React.ReactNode;
  header: string;
  description: string;
}

export const IconWithText = ({
  icon,
  header,
  description,
}: IconWithTextProps) => (
  <div className="flex flex-col gap-12 md:gap-32">
    <div className="flex flex-row items-start  gap-16 text-14-20 font-medium md:gap-24 md:text-16-24">
      <div className="rounded-[12px] p-10 shadow-d md:p-12">{icon}</div>
      <div className="flex flex-col pt-10">
        <div className="text-gray-950">{header}</div>
        <div className="text-gray-400">{description}</div>
      </div>
    </div>
  </div>
);

export const EnterpriseSection = () => {
  return (
    <div className="container-lg mx-auto grid max-w-[1160px] grid-cols-12  text-center md:mx-auto">
      <div className="col-span-full text-start">
        <div className="justify-start text-13-14 font-bold  text-blue-400">
          Enterprise
        </div>
        <h2 className="mt-16 flex flex-col self-stretch font-display text-[24px] leading-[100%] md:mt-40 md:text-[40px] ">
          Humanloop enables AI for the enterprise
        </h2>
      </div>
      <div className="col-span-full mt-32 text-left md:col-span-6">
        <div className="flex flex-row items-center justify-between md:items-start">
          {/* <div className="flex flex-col gap-24 md:flex-row md:gap-32">
            <Metric label={"Data generations"} value={"10M"} />
            <Metric label={"Feedback collected"} value={"1M"} />
            <Metric label={"Prompts"} value={"55,000"} />
          </div> */}
          <div className="flex text-14-20 text-gray-800 md:text-16-24">
            Adopt AI throughout your organization safely and securely with
            Humanloop's collaborative platform.
          </div>
        </div>
        <div className="mt-48 flex max-w-[448px] flex-col gap-24 text-left md:mt-64 md:gap-32">
          <IconWithText
            icon={<ShieldIcon className="h-16 w-16" />}
            header={"Data Privacy and Security"}
            description={
              "Activate LLMs with your private data, safely and securely. You own your data and models."
            }
          />
          <IconWithText
            icon={<SupportIcon className="h-16 w-16" />}
            header={"Fast support"}
            description={
              "End-to-end monitoring of your AI applications, support guarantees from trusted AI experts."
            }
          />
          <IconWithText
            icon={<LightBuildIcon className="h-16 w-16" />}
            header={"Shared learnings across your org"}
            description={
              "Adopt best practices and share learnings throughout your organization."
            }
          />

          <div className="flex gap-12 pl-52 md:pl-64">
            <Button
              size={32}
              className="whitespace-nowrap font-bold  text-gray-900 !shadow-button-1 hover:border-gray-200 hover:bg-gray-100 dark:text-white"
              styling="outline"
              shade="gray"
              href={DEMO_FORM_LINK}
              onClick={() => {
                analytics.track("Button Clicked", {
                  page: EventPage.Home,
                  section: EventSection.Enterprise,
                  purpose: EventPurpose.Sales,
                  url: DEMO_FORM_LINK,
                });
              }}
            >
              Talk to sales
            </Button>
            <Button
              size={32}
              target="_blank"
              // className="font-bold  text-gray-900 !shadow-button-1 hover:border-gray-200 hover:bg-gray-100 dark:text-white"
              styling="ghost"
              shade="gray"
              IconRight={ArrowUpRightIcon}
              href={VANTA_TRUST_REPORT_LINK}
              onClick={() => {
                analytics.track("Button Clicked", {
                  page: EventPage.Home,
                  section: EventSection.Enterprise,
                  purpose: EventPurpose.TrustReport,
                  url: VANTA_TRUST_REPORT_LINK,
                });
              }}
            >
              View trust report
            </Button>
          </div>
        </div>
      </div>
      <div className="hidden justify-center md:col-start-7 md:col-end-13 md:flex  ">
        <Globe className="md:flex md:w-full md:max-w-[400px]" />
      </div>
    </div>
  );
};
