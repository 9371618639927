import { useBreakpoints } from "hooks/useBreakpoints";
import React from "react";

export const GridBackground = ({
  children,
}: React.PropsWithChildren<{}>): JSX.Element => {
  return (
    <div className="relative">
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-10 hidden overflow-hidden md:block  ">
        <div className="absolute bottom-0 left-1/2 top-0 w-[1000px] translate-x-[20px] translate-y-[1px] transform overflow-hidden bg-[url('/assets/square.svg')] bg-top">
          <div className="absolute bottom-0 right-0 h-full w-full bg-gradient-to-r from-white to-transparent "></div>
          <div className="absolute bottom-0 right-0 h-[10%] w-full bg-gradient-to-t from-white to-transparent "></div>
        </div>
      </div>
      {children}
    </div>
  );
};
