import { PlayIconRounded } from "@components/library/Icons";
import { useMemo, useState } from "react";
import { DemoPlayerModal } from "./dialogs/DemoPlayerModal";
import VideoPlayer from "./VideoPlayer";
import { WebsiteImage } from "@components/library/Images/WebsiteImage";

export interface PlayButtonProps {
  onClick: (isOpen: boolean) => void;
}

const PlayButton = ({ onClick }: PlayButtonProps): JSX.Element => {
  return (
    <div
      className="cursor-pointer rounded-full border-gray-300  p-16 md:p-24"
      onClick={() => onClick(true)}
    >
      <div className="w-full rounded-full border bg-white p-6 shadow-playButton transition-all duration-200 ease-in-out active:bg-blue-100 active:shadow-d group-hover:w-[200px] group-hover:-translate-x-60 md:group-hover:w-[225px]">
        <div className="flex h-full w-full flex-row items-center  justify-start gap-8 rounded-full bg-gradient-to-b from-[#00002D] to-[#1D1D89] p-16 text-12-14 text-gray-600 transition-all duration-200 hover:to-blue-700 hover:ease-in-out md:gap-16 md:p-24">
          <PlayIconRounded
            className={`h-24 w-24 translate-x-[4px] md:h-32 md:w-32`}
          />
          <div className="absolute left-[65px] flex select-none flex-col gap-4 opacity-0 transition-all ease-in group-hover:flex group-hover:flex-col group-hover:opacity-100 group-hover:delay-200 md:left-[75px]">
            <div className="whitespace-nowrap text-12-12 font-bold text-white md:text-14-14 ">
              Watch the Demo
            </div>
            <div className="whitespace-nowrap text-12-12 font-bold text-gray-300 md:text-14-14">
              2 minutes
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DemoPlayerSection = () => {
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);

  return (
    <div className="flex h-[216px] flex-col items-center justify-start bg-gradient-to-b from-white from-5% via-blue-400 to-blue-950 pt-32 transition-all md:h-[480px] md:px-20 md:pt-[52px]">
      {/* Would be sweet if the image would skew towards the user on hover but haven't attempted. */}
      <div className="group flex h-full w-full max-w-[560px] flex-row md:max-h-[567px] md:max-w-[928px]">
        <div className="relative w-full overflow-hidden">
          {/* {!videoDialogOpen && ( */}
          {/* <div className="md:-translate-x-[71px] md:-translate-y-[71px] absolute left-1/2 top-1/2 z-10 -translate-x-[50px] -translate-y-[50px] transform">
            <PlayButton onClick={setVideoDialogOpen} />
          </div> */}
          {/* )} */}
          <div className="absolute bottom-0 flex h-full w-full justify-center ">
            <WebsiteImage className="flex max-h-[210px] max-w-[345px] md:max-h-[567px] md:max-w-[900px]" />
          </div>
        </div>
      </div>
      {/* Hiding video player dialog for now. */}
      {/* <DemoPlayerModal
        open={videoDialogOpen}
        onClose={() => setVideoDialogOpen(false)}
      >
        <VideoPlayer />
      </DemoPlayerModal> */}
    </div>
  );
};
